import React, { useState } from "react"
import PINModal from "../components/ContentBuilder/Modal/PINModal"
import Footer from "../components/ContentBuilder/Footer"
import Layout from "../templates/Page"
import Header from "../components/ContentBuilder/Header"
import ThankForModal from "../components/ContentBuilder/Modal/ThankForModal"

const AccountPage = () => {
  const [submited, setSubmited] = useState(false)
  const [showPIN, setShowPIN] = useState(false)

  return (
    <Layout hideHeader>
      <Header />

      <div className="container">
        <div className="account-content">
          <div className="d-flex flex-column">
            <div className="d-flex flex-column flex-sm-row flex-1">
              <div className="child">1</div>
              <div className="child">2</div>
              <div className="child">3</div>
              <div className="child">4</div>
              <div className="child">5</div>
            </div>
            <div className="d-flex flex-column flex-sm-row flex-2">
              <div className="d-flex flex-column flex-2">
                <div className="child">7</div>
              </div>
              <div className="d-flex flex-column flex-1">
                <div className="child">1</div>
                <div className="child">2</div>
              </div>
              <div className="d-flex flex-column flex-1">
                <div className="child">3</div>
                <div className="child">4</div>
              </div>
              <div className="d-flex flex-column flex-1">
                <div className="child">5</div>
                <div className="child">6</div>
              </div>
            </div>
            <div className="d-flex flex-column flex-sm-row flex-1">
              <div className="child">1</div>
              <div className="child">2</div>
              <div className="child">3</div>
              <div className="child">4</div>
              <div className="child">5</div>
            </div>
          </div>
          {!submited && (
            <div className="register-form-wrap">
              <div className="register-form">
                <div className="title-text">Welcome to Birdy</div>
                <form className="form-content">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control text-center"
                      id="firstName"
                      placeholder="FIRST NAME"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control text-center"
                      id="lastName"
                      placeholder="LAST NAME"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control text-center"
                      id="mobile"
                      placeholder="MOBILE NUMBER"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control text-center"
                      id="email"
                      placeholder="EMAIL ADDRESS"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control text-center"
                      id="password"
                      placeholder="PASSWORD"
                    />
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="rememberMe"
                    />
                    <label
                      className="form-check-label checkbox-label"
                      for="rememberMe"
                    >
                      Remember my email address when I next login
                    </label>
                  </div>
                  <div className="d-flex justify-content-center button-wrap">
                    <button
                      type="submit"
                      className="btn btn-submit"
                      onClick={() => setSubmited(true)}
                    >
                      CREATE
                    </button>
                  </div>

                  <button type="submit" className="btn btn-social">
                    <span>Continue with Facebook</span>

                    <div className="icon-social">
                      <img
                        src="/img/facebook.svg"
                        alt="facebook"
                        className="img-fluid"
                      />
                    </div>
                  </button>
                  <button type="submit" className="btn btn-social">
                    <span>Continue with Google</span>

                    <div className="icon-social">
                      <img
                        src="/img/google.svg"
                        alt="facebook"
                        className="img-fluid"
                      />
                    </div>
                  </button>
                  <button type="submit" className="btn btn-social">
                    <span>Continue with Apple</span>

                    <div className="icon-social">
                      <img
                        src="/img/apple.svg"
                        alt="facebook"
                        className="img-fluid"
                      />
                    </div>
                  </button>

                  <div className="already-member">
                    Already a member? <span>Log in</span>
                  </div>
                </form>
              </div>
            </div>
          )}

          {submited && !showPIN && <ThankForModal setShowPIN={setShowPIN} />}
        </div>
      </div>
      <PINModal isOpen={showPIN} onClose={() => setShowPIN(false)} />
    </Layout>
  )
}

export default AccountPage
