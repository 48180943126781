import React, { useState } from "react"
import classNames from "classnames"

const ThankForModal = ({ setShowPIN }) => {
  const [selected, setSelected] = useState([])

  const options = [
    { id: 1, name: "Knockdown Rebuild" },
    { id: 2, name: "Single Storey" },
    { id: 3, name: "Pool House" },
    { id: 4, name: "Double Storey" },
    { id: 5, name: "I have kids" },
    { id: 6, name: "Home Office" },
    { id: 7, name: "Media Room" },
    { id: 8, name: "I have pets" },
    { id: 9, name: "I have land" },
    { id: 10, name: "Granny Flat" },
  ]

  const handleSeleted = id => {
    if (!selected.includes(id)) {
      const clone = [...selected]

      clone.push(id)
      setSelected(clone)
    } else {
      let clone = []

      for (let item in selected) {
        if (selected[item] !== id) {
          clone.push(selected[item])
        }
      }

      setSelected(clone)
    }
  }
  return (
    <div className="register-form-wrap">
      <div className="register-form">
        <div className="title-text">Thanks Josiah! </div>
        <div className="section-title">CHOOSE YOUR HOME PREFERENCES</div>
        <div className="home-refer-wrap">
          {options.map(item => (
            <div
              key={item.id}
              className={classNames("button-choose", {
                "button-choose-active": selected.includes(item.id),
              })}
              onClick={() => handleSeleted(item.id)}
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className="section-title">COMMUNICATION PREFERENCES</div>
        <form className="communication">
          <div className="checkbox-wrap">
            <div className="checkbox-label">Email</div>
            <input type="checkbox" />
          </div>
          <div className="checkbox-wrap">
            <div className="checkbox-label">Phone or SMS</div>
            <input type="checkbox" />
          </div>
        </form>
        <div className="d-flex justify-content-center button-wrap">
          <button
            type="submit"
            className="btn btn-submit"
            onClick={() => setShowPIN(true)}
          >
            GET STARTED
          </button>
        </div>
      </div>
    </div>
  )
}

export default ThankForModal
