import React from "react"
import { Modal } from "react-bootstrap"
import PinInput from "../../PinInput"

const PINModal = ({ isOpen, onClose }) => {
  const onChange = value => {
    console.log(value)
  }
  return (
    <Modal show={isOpen} centered className="pin-wrap ">
      <div className="inst-title">
        Setup a 4 digit Quick access pin. Make logging in simple.
      </div>
      <div className="pinWrap">
        <PinInput
          length={4}
          focus
          // disabled
          secret
          type="numeric"
          onChange={onChange}
        />
      </div>
      <div className="finish-button-wrap d-flex justify-content-center">
        <button className="btn btn-submit-account" onClick={onClose}>
          FINISH
        </button>
      </div>
    </Modal>
  )
}

export default PINModal
